




































































import { mapState } from "vuex";
import { ImageFilter, ImageFilterOptions } from "@/store/types";
import { minLength, required } from "vuelidate/lib/validators";
import { slugify } from "@/utils/textSlugify";
import { fabric } from "fabric";
import Vue from "vue";
import { debounce } from "lodash";
const types = ["contrast", "saturation", "brightness", "blur", "rotation"];

fabric.textureSize = 5000;

export default Vue.extend({
  name: "ImageCreateFilterForm",
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filterName: "",
      saturation: false,
      contrast: false,
      brightness: false,
      blur: false,
      rotation: false, // hue rotation
      filtersOptions: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        blur: 0,
        rotation: 0
      },
      filtersToCreate: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        blur: 0,
        rotation: 0
      }
    };
  },
  computed: {
    ...mapState("users", ["userSettings"]),
    userFilters: function(): Array<ImageFilter> {
      if (this.userSettings && "imageFilters" in this.userSettings) {
        return this.userSettings.imageFilters;
      }

      return [];
    }
  },
  watch: {
    visible: function() {
      if (this.visible) {
        types.forEach(type => {
          this.filterHandler(true, type);
        });
      } else {
        this.$emit("onFilterBuilderClose");
      }
    }
  },
  methods: {
    getFilter(key: string) {
      const filterKey = key as keyof ImageFilterOptions;
      const val = this.filtersOptions[filterKey] * 0.01;
      const filters = {
        contrast: new fabric.Image.filters.Contrast({
          contrast: val
        }),
        saturation: new fabric.Image.filters.Saturation({
          saturation: val
        }),
        brightness: new fabric.Image.filters.Brightness({
          brightness: val
        }),
        // @ts-ignore
        blur: new fabric.Image.filters.Blur({
          blur: val
        }),
        // @ts-ignore
        rotation: new fabric.Image.filters.HueRotation({
          rotation: val
        })
      };

      return filters[filterKey];
    },
    filterHandler(checked: boolean, filterKey: string) {
      const filter = checked && this.getFilter(filterKey);
      this.$emit("createFilterPreview", types.indexOf(filterKey), filter);
    },
    updateFilterValue: debounce(function(
      this: any,
      value: number,
      filterKey: string
    ) {
      this.$emit(
        "applyFilterPreviewValue",
        types.indexOf(filterKey),
        filterKey,
        value * 0.01
      );
    },
    10),
    async createFilter() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const options = {} as ImageFilterOptions;

      Object.keys(this.filtersToCreate).forEach((id: string) => {
        const index = id as keyof ImageFilterOptions;
        options[index] = this.filtersToCreate[index] * 0.01;
      });

      const filter = {
        name: this.filterName,
        key: slugify(this.filterName),
        options: options
      } as ImageFilter;

      const nextFilter = this.userFilters.concat(
        JSON.parse(JSON.stringify({ ...filter }))
      );

      await this.$store.dispatch("users/updateUserSettings", {
        imageFilters: nextFilter
      });
      this.filterName = "";
      this.$emit("filterCreated");
    }
  },
  validations() {
    const uniq = (input: string) =>
      this.userFilters.map((elem: ImageFilter) => elem.name).indexOf(input) < 0;
    return {
      filterName: { required, minLength: minLength(3), unique: uniq }
    };
  }
});
